import { useEffect, useState } from "react";

import { auth } from "./firebase";

type UseChatResponseProps = {
  query: string | null;
  persona: string;
  uid: string;
  queryId: string | null;
};
const useChatResponse = ({
  query,
  persona,
  uid,
  queryId,
}: UseChatResponseProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [complete, setComplete] = useState<boolean>(false);
  const [messages, setMessages] = useState<string[]>([]);

  const [token, setToken] = useState<string>();

  useEffect(() => {
    const getToken = async () => {
      const user = auth.currentUser;
      setToken(await user?.getIdToken());
    };
    getToken();
  }, []);

  useEffect(() => {
    if (!query || !token) {
      setLoading(false);
      return;
    }

    setLoading(true);

    const es = new EventSource(
      `https://wattl.ai:8080/chat?query=${encodeURIComponent(
        query
      )}&persona=${persona}&uid=${uid}&queryId=${queryId}&token=${token}`
    );

    es.onmessage = (event) => {
      setLoading(false);
      setMessages((prevMessages) => [...prevMessages, event.data]);
    };

    es.addEventListener("end", () => {
      setComplete(true);
      es.close();
    });

    es.onerror = (error) => {
      es.close();
    };

    return () => {
      es.close();
    };
  }, [persona, query, queryId, token, uid]);

  return {
    loading,
    messages,
    complete,
  };
};

export default useChatResponse;
