import ReactMarkdown from "react-markdown";
import useChatResponse from "./useChatResponse";
import { useEffect } from "react";

type ChatResponseProps = {
  query: string | null;
  persona: string;
  uid: string;
  queryId: string | null;
  onUpdate: () => void;
};

const ChatResponse = ({
  query,
  persona,
  uid,
  queryId,
  onUpdate,
}: ChatResponseProps) => {
  const { messages, loading } = useChatResponse({
    query,
    persona,
    uid,
    queryId,
  });

  const content = loading ? "..." : messages.join("");

  useEffect(() => {
    onUpdate();
  }, [messages, onUpdate]);

  return (
    <ReactMarkdown className={`message assistant`}>{content}</ReactMarkdown>
  );
};

export default ChatResponse;
