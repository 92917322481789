import { getAuth, onAuthStateChanged, signInAnonymously } from "firebase/auth";

import { getFirestore } from "firebase/firestore";
import { initializeApp } from "firebase/app";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBIcPuTJgYLSXo28kryeKFw3gf0YcieyuY",
  authDomain: "web-api-d678b.firebaseapp.com",
  projectId: "web-api-d678b",
  storageBucket: "web-api-d678b.appspot.com",
  messagingSenderId: "188782547318",
  appId: "1:188782547318:web:22c88511796c4419fc2a57",
  measurementId: "G-VNVX9DTQMY",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);

export { auth, signInAnonymously, onAuthStateChanged, db };
