// call the API

import { auth } from "./firebase";

const callClearMessages = async () => {
  const user = auth.currentUser;
  const token = await user?.getIdToken();

  const response = await fetch(
    `https://wattl.ai:8080/clearMessages?token=${token}`
  );

  if (!response.ok) {
    throw new Error("Failed to clear messages");
  }

  await response.json();
};

export { callClearMessages };
