import "./styles.css"; // Import the CSS file

import { collection, onSnapshot, query } from "firebase/firestore";
import {
  faArrowUp,
  faSpinner,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import ChatResponse from "./ChatResponse";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactMarkdown from "react-markdown";
import { callClearMessages } from "./api";
import { db } from "./firebase"; // adjust the path as necessary
import { v4 as uuidv4 } from "uuid";

const QueryInput = ({ uid, persona }: { uid: string; persona: string }) => {
  const [messages, setMessages] = useState<
    {
      content: string;
      role: "user" | "assistant";
      created_at: number;
      queryId: string;
    }[]
  >([]);
  const inputRef = useRef<HTMLTextAreaElement>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const [threadId, setThreadId] = useState<string | null>(null);
  const [queryString, setQueryString] = useState<string | null>();
  const [sentQueryString, setSentQueryString] = useState<string | null>(null);

  // const [responseIsLoading, setResponseIsLoading] = useState<boolean>(false);
  const [queryId, setQueryId] = useState<string | null>(null);

  const messagesEndRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const unsubscribe = onSnapshot(
      query(collection(db, "users", uid, "chat")),
      (querySnapshot) => {
        if (querySnapshot.docs && querySnapshot.docs.length > 0) {
          setThreadId(querySnapshot.docs[0].id);
        }
      }
    );

    return () => unsubscribe();
  }, [uid]);

  useEffect(() => {
    if (!threadId) return;

    const unsubscribe = onSnapshot(
      query(collection(db, "users", uid, "chat", threadId, "messages")),
      (querySnapshot) => {
        const messages = querySnapshot.docs.map((doc) => {
          const { content, role, created_at, queryId } = doc.data();
          return {
            content,
            role,
            created_at,
            queryId,
          };
        });
        messages.sort((a, b) => a.created_at - b.created_at);
        setMessages(messages);
      }
    );

    return () => unsubscribe();
  }, [threadId, uid]);

  useEffect(() => {
    inputRef.current?.focus();
  }, []);

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  const handleSendMessage = async () => {
    if (inputRef.current && queryString) {
      inputRef.current.value = "";
      setLoading(true);
      setSentQueryString(queryString);
      setQueryString("");
      setQueryId(uuidv4());
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleSendMessage();
    }
  };

  const handleClearMessages = useCallback(() => {
    setMessages([]);
    callClearMessages();
  }, []);

  useEffect(() => {
    if (inputRef.current) {
      // Reset height to allow shrinking when deleting text
      inputRef.current.style.height = "auto";
      // Set the height according to the scroll height (content height)
      inputRef.current.style.height = `${inputRef.current.scrollHeight}px`;
    }
  }, [queryString]);

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setQueryString(event.target.value);
  };

  const messagesView = useMemo(() => {
    console.log(`messages length: ${messages.length}`);
    const respondedMessages = messages.filter((m) => m.queryId !== queryId);

    const receivedResponse = messages.length - respondedMessages.length === 2;

    if (receivedResponse) {
      setSentQueryString(null);
      setLoading(false);
    }
    return (
      <>
        {(receivedResponse ? messages : respondedMessages).map(
          (message, index) => (
            <ReactMarkdown className={`message ${message.role}`}>
              {message.content}
            </ReactMarkdown>
          )
        )}
        {!receivedResponse && (
          <>
            <ReactMarkdown className={`message user`}>
              {sentQueryString}
            </ReactMarkdown>
            <ChatResponse
              query={sentQueryString}
              uid={uid}
              persona={persona}
              queryId={queryId}
              onUpdate={() => {
                messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
              }}
            />
          </>
        )}
      </>
    );
  }, [messages, persona, queryId, sentQueryString, uid]);

  return (
    <div className="chat-container">
      {messages?.length > 0 && (
        <div className="tools-container">
          <button className="tools-button" onClick={handleClearMessages}>
            <FontAwesomeIcon
              icon={faTrash}
              style={{
                color: "white",
              }}
            />
          </button>
        </div>
      )}
      <div className="messages-container">
        {messagesView}

        <div ref={messagesEndRef} />
      </div>

      <div className="input-container">
        <textarea
          ref={inputRef}
          placeholder="How can I help you?"
          className="query-input"
          onKeyDown={handleKeyDown}
          onChange={handleChange}
          rows={1}
          disabled={loading}
        />

        {loading ? (
          <div className="submit-button">
            <FontAwesomeIcon icon={faSpinner} spin style={{ color: "white" }} />
          </div>
        ) : (
          <button className="submit-button" onClick={handleSendMessage}>
            <FontAwesomeIcon
              icon={faArrowUp}
              style={{
                color: "white",
              }}
            />
          </button>
        )}
      </div>
    </div>
  );
};

export default QueryInput;
