import * as THREE from "three";

import React, { useEffect, useMemo, useRef, useState } from "react";

import PerlinNoise from "./PerlinNoise";

const MeshAnimation: React.FC = () => {
  const mountRef = useRef<HTMLDivElement>(null);
  const noise = useMemo(() => new PerlinNoise(), []);
  const [mouseX, setMouseX] = useState(0);
  const [mouseY, setMouseY] = useState(0);

  useEffect(() => {
    const currentRef = mountRef.current;
    if (!currentRef) return;

    // Scene setup
    const scene = new THREE.Scene();
    const camera = new THREE.PerspectiveCamera(
      75,
      window.innerWidth / window.innerHeight,
      0.1,
      1000
    );
    const renderer = new THREE.WebGLRenderer();
    renderer.setSize(window.innerWidth, window.innerHeight);
    currentRef.appendChild(renderer.domElement);

    // Create geometry using PlaneGeometry
    const geometry = new THREE.PlaneGeometry(10, 10, 50, 50);
    const material = new THREE.MeshBasicMaterial({
      color: 0x333333,
      wireframe: true,
    });
    const mesh = new THREE.Mesh(geometry, material);
    scene.add(mesh);

    mesh.rotation.x = -0.9;
    mesh.rotation.z = -0.05;

    camera.position.z = 1;

    const updateVertices = () => {
      const time = Date.now() * 0.0001;

      const positionAttribute = geometry.attributes.position;
      const vertexCount = positionAttribute.count;

      for (let i = 0; i < vertexCount; i++) {
        const x = positionAttribute.getX(i);
        const y = positionAttribute.getY(i);

        const z = noise.noise(x * 0.5, y * 0.5 + time) * 1;

        positionAttribute.setZ(i, z);
      }

      positionAttribute.needsUpdate = true;
    };

    // Animation loop
    const animate = () => {
      requestAnimationFrame(animate);
      updateVertices();

      // Update rotation based on mouse position
      mesh.rotation.x = -0.9 - mouseY * Math.PI * 0.05; // Adjust the multiplier to control sensitivity
      mesh.rotation.z = mouseX * Math.PI * 0.05; // Adjust the multiplier to control sensitivity

      renderer.render(scene, camera);
    };

    animate();

    // Handle window resizing
    const handleResize = () => {
      camera.aspect = window.innerWidth / window.innerHeight;
      camera.updateProjectionMatrix();
      renderer.setSize(window.innerWidth, window.innerHeight);
    };

    window.addEventListener("resize", handleResize);

    // Function to handle mouse movement and update rotation
    const handleMouseMove = (event: MouseEvent) => {
      const normalizedX = (event.clientX / window.innerWidth) * 2 - 1;
      const normalizedY = -(event.clientY / window.innerHeight) * 2 + 1;

      setMouseX(normalizedX);
      setMouseY(normalizedY);
    };

    window.addEventListener("mousemove", handleMouseMove);

    // Cleanup
    return () => {
      if (currentRef) {
        currentRef.removeChild(renderer.domElement);
      }
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("mousemove", handleMouseMove);
    };
  }, [mouseX, mouseY, noise]);

  return <div ref={mountRef} />;
};

export default MeshAnimation;
