import "./App.css";

import { auth, onAuthStateChanged, signInAnonymously } from "./firebase";
import { useEffect, useState } from "react";

import Header from "./Header";
import MeshAnimation from "./MeshAnimation";
import { PersonaName } from "./persona/Persona";
import PersonaSelector from "./persona/PersonaSelector";
import QueryInput from "./Chat";
import { User } from "firebase/auth";
import { callClearMessages } from "./api";

function App() {
  const [user, setUser] = useState<User | null>(null);

  const [selectedPersona, setSelectedPersona] =
    useState<PersonaName>("default");
  useEffect(() => {
    const savedPersona = localStorage.getItem("selectedPersona");
    setSelectedPersona((savedPersona as PersonaName) ?? "default");
  }, []);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(user);
      } else {
        setUser(null);
      }
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    signInAnonymously(auth);
  }, []);

  return (
    <div className="App">
      <Header
        persona={selectedPersona}
        onSelect={async (selectedPersona) => {
          setSelectedPersona(selectedPersona);
          await callClearMessages();
        }}
      />
      {user && (
        <div className="screen-overlay-container">
          <QueryInput uid={user?.uid} persona={selectedPersona ?? "default"} />
        </div>
      )}

      <MeshAnimation />
    </div>
  );
}

export default App;
