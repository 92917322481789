import Persona, { PersonaName } from "./Persona";
import React, { useEffect, useState } from "react";

const personas: PersonaName[] = [
  "default",
  "zen",
  "comedian",
  "intern",
  "poet",
];

const PersonaSelector: React.FC<{
  persona: PersonaName;
  onSelect: (persona: PersonaName) => void;
}> = ({ onSelect, persona }) => {
  // const [selectedPersona, setSelectedPersona] = useState<PersonaName | null>(
  //   null
  // );
  const handlePersonaClick = (persona: PersonaName) => {
    // setSelectedPersona(persona);
    onSelect(persona);
  };

  // useEffect(() => {
  //   if (selectedPersona) {
  //     localStorage.setItem("selectedPersona", selectedPersona);
  //   }
  // }, [selectedPersona]);

  return (
    <div className="persona-selector-grid">
      {personas.map((p) => (
        <div
          key={p}
          className={`persona-selector-item ${p === persona ? "selected" : ""}`}
          onClick={() => handlePersonaClick(p)}
        >
          <Persona type={p} />
        </div>
      ))}
    </div>
  );
};

export default PersonaSelector;
