import { PersonaName, PersonaTypes } from "./Persona";

import PersonaSelector from "./PersonaSelector";
import { useState } from "react";

type PersonaSummaryProps = {
  persona: PersonaName;
  onSelect: (persona: PersonaName) => {};
};
const PersonaSummary = ({ persona, onSelect }: PersonaSummaryProps) => {
  const [showSelector, setShowSelector] = useState<boolean>(false);
  const personaObject = PersonaTypes[persona];
  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <div
        style={{
          cursor: "pointer",
          color: "white",
          marginTop: -8,
          backgroundColor: "#333",
          border: "1px solid #888",
          padding: 12,
          borderRadius: "0px 0px 10px 10px",
        }}
        onClick={() => {
          setShowSelector((s) => !s);
        }}
      >
        Persona: {personaObject.name}
        <span style={{ marginLeft: 8 }}>{showSelector ? "▲" : "▼"}</span>
      </div>
      {showSelector && (
        <div
          style={{
            position: "absolute",
            right: 0,
            left: 0,
            top: 50,
            display: "flex",
            justifyContent: "center",
          }}
        >
          <PersonaSelector
            persona={persona}
            onSelect={(p) => {
              onSelect(p);
              setShowSelector(false);
            }}
          />
        </div>
      )}
    </div>
  );
};

export default PersonaSummary;
